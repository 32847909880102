import { coreKeeperAppReady, userKeeper } from '@mail-core/dashboard';
import { IS_PHONE_BROWSER } from 'reactApp/appHelpers/configHelpers';

import { logger } from './logger';

export const sendAppReady = (err?: any) => {
    // Бизнес-метрика, не путать с biz-юзером
    userKeeper.timeEnd('biz-app-ready', {
        // Appendix может содержать переменную, но тогда страдает гереация дашборда.
        // Поэтому, чтобы получить не один график, а четрые, нужно использовать тернарный оператор.
        appendix: IS_PHONE_BROWSER
            ? // Mobile
              (err
                ? 'mobile-err'
                : 'mobile-ok')
            : // Base
            (err
            ? 'err'
            : 'ok'),
        score: 'app:ready',
    });

    const logDetail = {
        date: new Date().toISOString(),
        time: performance.now(),
        error: err,
    };
    if (err) {
        logger.error('AppReady Ready', logDetail);
    } else {
        logger.done('AppReady Ready', logDetail);
    }

    coreKeeperAppReady(err);

    try {
        window.ContentLoader?.setJSLoaded?.();
        logger.done('Content Loader — ok');
    } catch (error) {
        logger.error('Content Loader failed', { ...logDetail, error });
    }
};
