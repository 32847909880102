/**
 * Расширение класса Date
 * @author	"RubaXa"		<trash@rubaxa.org>
 */

define('ajs/__date/ajs__date', [
	'jquery',
	'ajs/__string/ajs__string',
	'ajs/__lang/ajs__lang'
], function ($) {
	/** @namespace patron.LANG */
	function now(){
		return (new Date).getTime();
	}

	/** @namespace window.timeZone */
	/** @namespace window.TIMEZONE */

	$.extend(Date, {
		is: function (d)
		{
			if( d )
			{
				return	d.setTime || /(^\d+$|^((\d{1,4}[-:\s\/]?)+)$)/.test(d+'');
			}
			return	!1;
		},



		_timer:		(new Date()).getTime(),
		timeZone:	(window.timeZone || window.TIMEZONE || 4),

		getTime: function ()
		{
			return	this.changeNow().getNow().getTime();
		},

		getUnixtime: function ()
		{
			return	Math.round(this.getTime() / 1000);
		},


		setNow: function (time)
		{
			this._now_			= new Date(time);
			this._today_		= new Date(time);
			this._yesterday_	= new Date(time);

			this._today_.setHours(0);
			this._today_.setMinutes(0);

			this._yesterday_.setTime(this._today_.getTime());
			this._yesterday_.setDate(this._yesterday_.getDate()-1);

			return	this;
		},

		getNow: function ()
		{
			return	this.changeNow()._now_;
		},

		changeNow: function ()
		{
			var n = Date.now();
			Date.setNow(Date._now_.getTime() + (n - Date._timer));
			Date._timer	= n;

			return	this;
		},

		_f:	function (n){ return	((n > 9) ? n : '0' + n); },


		_parse: Date.parse,
		parse: function (s/*StringOrInt*/)/*Date*/
		{
			if( s && s.setTime )	return	s;

			try
			{
				var D = /^\d+$/.test(s) ? new Date(s) : Date._parse( s );
				if( D.constructor != Date ) D = new Date(String(s).replace(/^(\d{4})-(\d{2})-(\d{2})/, '$2/$3/$1'));
			}
			catch( e )
			{
				debug.log('Date', e);
			}

			return	D;
		}

	});

	if( !Date.now ) {
		Date.now = function() {
			return (new Date).getTime();
		}
	}

	$.extend(Date.prototype, {
		getLocalToday: function () {
			var date = new Date();
			date.setHours(0);
			date.setMinutes(0);
			date.setSeconds(0);
			return date;
		},

		getLocalYesterday: function () {
			var date = this.getLocalToday();
			date.setDate(date.getDate() - 1);
			return date;
		},

		isNow: function (interval)
		{
			Date.changeNow();
			return	(Date._now_.getTime() - this.getTime()) <= interval;
		},

		isToday: function () {
			return this.toDateString() === this.getLocalToday().toDateString();
		},

		isYesterday: function () {
			return this.toDateString() === this.getLocalYesterday().toDateString();
		},

		isThisYear: function () {
			return this.getFullYear() === this.getLocalToday().getFullYear();
		},

		setTimeZone: function (z)
		{
			this.timeZone	= z * 1;
			return	this;
		},

		format: function (f)
		{
			var t = this.getTime(), r = '', f = f.split('');

			/* MAIL-7804
			this.setTime( t + (this.getTimezoneOffset() + this.timeZone*60) * 60000);
			*/

			for( var i = 0, n = f.length; i < n; i++ )
			{
				switch( f[i] )
				{
					case 'Y':	r += this.getFullYear(); break;
					case 'y':	r += Date._f(this.getFullYear() % 100); break;

					case 'N':	r += String.ucfirst(Lang.get('Date').months[1][this.getMonth()]); break;
					case 'n':	r += Lang.get('Date').months[1][this.getMonth()]; break;

					case 'M':	r += Date._f(this.getMonth()+1); break;
					case 'm':	r += this.getMonth()+1; break;

					case 'D':	r += Date._f(this.getDate()); break;
					case 'd':	r += this.getDate(); break;

					case 'H':	r += Date._f(this.getHours()); break;
					case 'h':	r += this.getHours(); break;

					case 'I':	r += Date._f(this.getMinutes()); break;
					case 'i':	r += this.getMinutes(); break;

					case 'S':	r += Date._f(this.getSeconds()); break;
					case 's':	r += this.getSeconds(); break;

					default:		r += f[i]; break;
				}
			}

			this.setTime(t);

			return	r;
		},

		getLocaleDateShort: function () {
			var str = '';
			if (this.isToday()) {
				str = this.getLocaleTime();
			} else if (this.isThisYear()) {
				str = this.getLocaleShortDayMonth();
			} else {
				str = this.getLocaleShortDayMonthYear();
			}
			return str;
		},

		getLocaleDateFull: function () {
			var str = '';
			if (this.isToday()) {
				str = this.getLocaleTodayTime();
			} else if (this.isYesterday()) {
				str = this.getLocaleYesterdayTime();
			} else if (this.isThisYear()) {
				str = this.getLocaleDayMonthTime();
			} else {
				str = this.getLocaleDayMonthYearTime();
			}
			return str;
		},

		getLocaleFullDate: function () {
			var day = String.ucfirst(Lang.get('Date').weekdays_full[this.getDay()]);
			var timezoneOffset = -this.getTimezoneOffset(), gmtStr = '';

			if (timezoneOffset > 0) {
				gmtStr += '+';
			} else if (timezoneOffset < 0) {
				gmtStr += '-';
			}

			gmtStr += Date._f(timezoneOffset / 60) + ':' + Date._f(timezoneOffset % 60);

			if (patron.LANG == 'en_US') {
				return day + ', ' + this.format('n d, Y') + ' ' + this.getLocaleTime() + ' ' + gmtStr;
			}
			return day + ', ' + this.format('d n Y') + ', ' + this.getLocaleTime() + ' ' + gmtStr;
		},

		getLocaleTodayTime: function () {
			return String.ucfirst(Lang.get('Date').today) + ', ' + this.getLocaleTime();
		},

		getLocaleYesterdayTime: function () {
			return String.ucfirst(Lang.get('Date').yesterday) + ', ' + this.getLocaleTime();
		},

		getLocaleDayMonthTime: function () {
			if (patron.LANG == 'en_US') {
				return this.format('N d') + ', ' + this.getLocaleTime();
			}
			return this.format('d n') + ', ' + this.getLocaleTime();
		},

		getLocaleDayMonthYearTime: function () {
			if (patron.LANG == 'en_US') {
				return this.format('N d, Y') + ' ' + this.getLocaleTime();
			}
			return this.format('d n Y') + ', ' + this.getLocaleTime();
		},

		getLocaleTime: function () {
			if (patron.LANG == 'en_US') {
				return (this.getHours() % 12 || 12) + ':' + this.format('I') + ' ' + (this.getHours() < 12 ? 'AM' : 'PM');
			}
			return this.format('h:I');
		},

		getLocaleShortDayMonth: function () {
			var month = Lang.get('Date').months[2][this.getMonth()];
			if (patron.LANG == 'en_US') {
				return month + ' ' + this.format('d');
			}
			return this.format('d') + ' ' + month;
		},

		getLocaleShortDayMonthYear: function () {
			if (patron.LANG == 'en_US') {
				return this.format('M/D/y');
			}
			return this.format('D.M.y');
		},

		diffYears: function (Y, M, D)
		{
			if( isNaN(Y) )
			{
				Y	= Date.parse(Y);
				D	= Y.getDate();
				M	= Y.getMonth() + 1;
				Y	= Y.getFullYear();
			}
			var cM	= this.getMonth()+1;

			Y	= this.getFullYear() - Y;

			if( (M > cM) || (D > this.getDate() && M == cM) ) Y--;

			return	Y;
		}

	});
	// END: Date();

	Date.setNow(window.time || window.TIME || Date.now());

	setInterval(function (){
		Date.changeNow();
	}, 60000);
});
