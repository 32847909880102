import type { SendFunction, Xray } from '@mail/xray';
import { IS_WEBVIEW } from 'reactApp/appHelpers/configHelpers';

type sendFunctionParams = Parameters<SendFunction>;

// CLOUDWEB-13866: Переопределяем функции send и sendImmediatly для того, чтобы вызвать оригинальную функцию еще раз для webview.
if (!process.env.SSR && IS_WEBVIEW && window?.xray?.send && window?.xray?.sendImmediately) {
    const updateXRayFunctions = (original: (...args: sendFunctionParams) => void) => {
        return (...args: sendFunctionParams) => {
            const [t, params, ...rest] = args;

            const webviewParams = {
                ...params,
                p: 'cloud-webview',
            };

            original(t, params, ...rest);
            original(t, webviewParams, ...rest);
        };
    };

    window.xray.send = updateXRayFunctions(window.xray.send);
    window.xray.sendImmediately = updateXRayFunctions(window.xray.sendImmediately);
}

export const xray = process.env.SSR ? ({} as Xray) : ((window as any).xray as Xray);
