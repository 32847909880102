define(function() {
    'use strict';
    const plural = require('lib/plural').default;

    function formatErrors(errors) {
        return errors.map(function(error) {
            if (typeof error !== 'string') {
                var type = error.error.toLowerCase();

                if ((type == 'arguments/required' || type == 'arguments/invalid') && error.field) {
                    type += '/' + error.field.toLowerCase();
                }

                return type;
            } else {
                return error;
            }
        });
    }

    function formatTexts(errors) {
        Object.keys(errors).forEach(function(error) {
            var texts = errors[error];

            if (typeof texts == 'string') {
                errors[error] = [texts, texts, texts];
            } else if (texts.length == 2) {
                texts.push(texts[1]);
            }
        });

        return errors;
    }

    function calculateErrorTypes(errors, texts) {
        var errorTypes = {};

        errors.forEach(function(error) {
            var type,
                path = error.split('/'),
                errors = [];

            path.forEach(function(e, i) {
                'use strict';

                errors.push(path.slice(0, i + 1).join('/'));
            });

            errors.reverse();

            errors.forEach(function(error) {
                'use strict';

                if (!type && texts[error]) {
                    type = error;
                }
            });

            if (!type) {
                type = 'default';
            }

            if (!errorTypes[type]) {
                errorTypes[type] = 0;
            }

            errorTypes[type]++;
        });

        return errorTypes;
    }

    function errorsCountFromTypes(types) {
        var count = 0;

        Object.keys(types).forEach(function(value) {
            count += value;
        });

        return count;
    }

    var Errors = {
        getErrorsFromResponses: function(responses) {
            var errors = [];

            responses = [].concat(responses);

            responses.forEach(function(res) {
                if (res.error) {
                    errors = errors.concat(res);
                } else {
                    errors.push('default');
                }
            });

            errors = formatErrors(errors);

            return errors;
        },

        getErrorMessage: function(errors, texts, replaces) {
            errors = formatErrors(errors);
            texts = formatTexts(texts);

            var errorTypes = calculateErrorTypes(errors, texts),
                message,
                types = Object.keys(errorTypes);

            if (types.length == 1) {
                message = types[0];
            } else {
                message = 'default';

                errorTypes.default = errorsCountFromTypes(errorTypes);
            }

            return this._doErrorsTextReplaces(texts[message][plural(errorTypes[message])], replaces);
        },

        getErrorMessages: function(errors, texts, replaces) {
            errors = formatErrors(errors);

            texts = formatTexts(texts);

            var errorTypes = calculateErrorTypes(errors, texts),
                types = Object.keys(errorTypes);

            if (types.length > 1 && errorTypes.default) {
                delete errorTypes.default;
            }

            return this._doErrorsTextReplaces(
                Object.keys(errorTypes).map(function(type) {
                    return plural(errorTypes[type], texts[type]);
                }),
                replaces
            );
        },

        _doErrorsTextReplaces: function(errors, replaces) {
            var replacer = function(error) {
                    keys.forEach(function(key) {
                        error = error.replace(key, replaces[key]);
                    });

                    return error;
                },
                keys = replaces && Object.keys(replaces);

            if (!keys || !keys.length) {
                return errors;
            } else {
                if (Array.isArray(errors)) {
                    return errors.map(replacer);
                } else {
                    return replacer(errors);
                }
            }
        },
    };

    return Errors;
});
