define(function(require, exports, module) {
'use strict';

    var base58 = require('./base58');
    var stringToUint8Array = require('./stringToUint8Array');

    function getBase58Id(length) {
        var id = '';

        while (id.length !== length) {
            id = base58.encode(stringToUint8Array(Math.round(10e16 + Math.random() * 10e16).toString(36).slice(0, length - 2)));
        }

        return id;
    }

    module.exports = getBase58Id;
});
