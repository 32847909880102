function Sequence(dataSet, promiseFabric) {
    this._dataSet = dataSet;
    this._promiseFabric = promiseFabric;
    this._results = new Array(dataSet.length);
    this._promise = new Promise(this._resolver.bind(this));
}

export { Sequence };

Sequence.prototype = {
    constructor: Sequence,

    _resolver: function (resolve, reject) {
        var that = this,
            i = -1;

        (function doNextJob() {
            i++;

            if (i >= that._dataSet.length) {
                that._resolve(resolve);
                return;
            }

            var data = that._dataSet[i];

            that._promiseFabric(data)
                .then(function (results) {
                    that._results[i] = results;
                    doNextJob();
                })
                .catch(function () {
                    that._resolve(resolve);
                });
        })();
    },

    _resolve: function (resolve) {
        // заполнить дырки ????
        for (var i = 0, l = this._results.length; i < l; i++) {
            if (this._results[i] == void 0) {
                this._results[i] = void 0;
            }
        }

        resolve(this._results);
    },

    then: function (handler) {
        return this._promise.then(handler);
    },

    catch: function (handler) {
        return this._promise.catch(handler);
    },
};
