define(function (require, exports, module) {
	'use strict';

	var months = {
		short: [
			'янв',
			'фев',
			'мар',
			'апр',
			'мая',
			'июн',
			'июл',
			'авг',
			'сен',
			'окт',
			'ноя',
			'дек'
		],
		full: [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря'
		],
	};

	/**
	 * @namespace
	 */
	var dateTime = {
		/**
		 * @param {Date|number} [year] – full year or instance of Date
		 * @returns {Boolean}
		 */
		isLeapYear: function (date) {
			var year = date;

			if (!date) {
				date = new Date();
			}

			if (date instanceof Date) {
				year = date.getFullYear();
			}

			return year % 4 == 0 && year % 100 != 0 || year % 400 == 0;
		},

		/**
		 * @param {Date|number} [date] – timestamp or instance of Date
		 * @returns {number}
		 */
		getMonthLength: function (date) {
			if (!date) {
				date = new Date();
			}

			if (typeof date == 'number') {
				date = new Date(date);
			}

			var month = date.getMonth();

			if (month == 1 && dateTime.isLeapYear(date)) {
				return 29;
			}

			return [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
		},

		/**
		 * @param {Date|number} date – timestamp or instance of Date
		 * @param {string} pattern – like "hh:mm:ss.SSS dd.MM.yyyy (z)"
		 * @returns {string}
		 */
		format: function (date, pattern) {
			if (typeof date == 'number') {
				date = new Date(date);
			}

			return pattern.match(/[hmsSdMyz]+/g).reduce(function (result, pattern) {
				let value;
				let isMonthsName;

				switch (pattern.charAt(0)) {
					case 'h':
						value = date.getHours();

						break;

					case 'm':
						value = date.getMinutes();

						break;

					case 's':
						value = date.getSeconds();

						break;

					case 'S':
						value = date.getMilliseconds();

						break;

					case 'd':
						value = date.getDate();

						break;

					case 'M':
						var month = date.getMonth();

						if (pattern.length > 2) {
                            isMonthsName = true;

							if (pattern.length == 3) {
								value = months.short[month];
							} else {
								value = months.full[month];
							}
						} else {
							value = month + 1;
						}

						break;

					case 'y':
						if (pattern.length == 4) {
							value = date.getFullYear();
						} else {
							value = date.getYear();

							if (value > 100) {
								value -= 100;
							}
						}

						break;

					case 'z':
						value = date.getTimezoneOffset() / 60;

						break;
				}

				value = String(value);

				if (!isMonthsName && pattern.length > value.length) {
					value = '0000'.slice(0, pattern.length - value.length) + value;
				}

				return result.replace(pattern, value);
			}, pattern);
		}
	};

	module.exports = dateTime;
});
