/**
 * jQuery extension, add support `classList`.
 *
 * @author	RubaXa	<trash@rubaxa.org>
 * @license	MIT
 */

define('toolkit-common/jquery/__classList/jquery__classList', ['jquery'], function($) {
	var
		  _rspace = /\s+/
		, _$cache = $('<div/>')

		, _div = _$cache[0]
		, _classList = _div.classList
		, _supportsArgs
	;


	if( _classList ){
		_classList.add('-a', 'b-');
		_supportsArgs = /-a/.test(_div.className) && /b-/.test(_div.className);


		/**
		 * Has class
		 *
		 * @param {string} className
		 * @returns {boolean}
		 */
		$.fn.hasClass = function (className){
			className = $.trim(className);
			var i = this.length;

			while( i-- ){
				if( this[i].nodeType === 1 && this[i].classList.contains(className) ){
					return	true;
				}
			}

			return	false;
		};


		/**
		 * Add class
		 *
		 * @param	{string|function}  value
		 * @returns	{jQuery}
		 */
		$.fn.addClass = _factory('add');


		/**
		 * Remove class
		 *
		 * @param	{string|function}  value
		 * @returns	{jQuery}
		 */
		$.fn.removeClass = _factory('remove');
	}


	function _factory(method){
		return function (value){
			var typeOf = typeof value, i = this.length;

			if( 'string' === typeOf ){
				value = $.trim(value);

				if( "" !== value ){
					value = value.split(_rspace);

					var j, n = value.length, list;

					while( i-- ){
						list = this[i].classList;

						if( 1 === n ){
							list[method](value[0]);
						}
						else if( _supportsArgs ){
							list[method].apply(list, value);
						}
						else {
							for( j = 0; j < n; j++ ){
								list[method](value[j]);
							}
						}
					}
				}
			}
			else if( (void 0 === value) && ('remove' === method) ){
				while( i-- ){
					this[i].className = '';
				}
			}
			else if( 'function' === typeOf ){
				_each(this, method + 'Class', value);
			}

			return	this;
		}
	}


	function _each($el, method, callback){
		$el.each(function (i){
			_$cache[0] = this;
			_$cache[method]( callback.call(this, i, _$cache.prop('class')) );
		});
	}


	// Cleanup
	_$cache[0] = _div = _classList = null;
});
