/* eslint no-console:"off" */

import { createLogEntry, createLogger, octoLogger } from '@mail-core/logger';
import { interceptRuntimeErrors } from '@mail-core/logger/error/runtime';
import { arrayOutput, universalOutput } from '@mail-core/logger/output/preset';
import { isFeature } from 'Cloud/Application/FeaturesEs6';

/** Добавлять в лог только Warning & Errors */
const isSilent = !isFeature('logger-verbose');
const isSSR = !!process.env.SSR;

/** Записи логгера в SSR-режиме */
export const ssrLogEntries: string[] = [];

/** Общие настройки логгера */
const options = {
    output: isSSR ? arrayOutput({ out: ssrLogEntries }) : universalOutput(),
    silent: false,
};

const noop = () => {};

/** Логгер */
export const logger = (() => {
    // Only Warning & Errors
    if (isSilent) {
        return createLogger(
            // Options
            options,

            // Methods
            ({ logger }) => ({
                warn(...args: any[]) {
                    logger.add(createLogEntry('warn', '⚠️ ', 'warn', args));
                },

                error(...args: any[]) {
                    logger.add(createLogEntry('error', '❌ ', 'error', args));
                },

                log: noop,
                info: noop,
                done: noop,
                verbose: noop,
            })
        );
    }

    // Default Logger
    octoLogger.setup(options);

    return octoLogger;
})();

/** Сохранение лога на комп */
async function saveLogAsBlob() {
    const filename = `log-${new Date().toISOString()}.txt`;
    const fileSaverPromise = import('file-saver');
    const blobPromise = import('@mail-core/logger/helper').then((helper) => {
        const cleaner = helper.createCleaner(helper.defaultCleaners);

        return helper.saveLogAsBlob({
            logger,
            cleaner,
            detail: {
                /** Например ActiveEmail или другие полезности */
            },
        });
    });

    return Promise.all([blobPromise, fileSaverPromise]).then(([blob, { default: fileSaver }]) => {
        /** Cохранение файла на комп пользователя */
        fileSaver.saveAs(blob, filename);

        return {
            blob,
            filename,
        };
    });
}

/** Сохранение лог по шоркату */
function initSaveLogByShortcut(combo: string) {
    import('hotkeys-js').then(({ default: hotkey }) => {
        hotkey(combo, () => {
            saveLogAsBlob().catch((error) => {
                console.error('Отправка лога не удалась:', error);
            });
        });
    });
}

/** Сохранение лога через публичный метод */
function initSaveLogByPublicAPI(method: string) {
    self[method] = saveLogAsBlob;
}

// Отправка лога по комбинации клавиш, либо из консоли
(function initSaveLog() {
    if (isSSR) {
        return;
    }

    initSaveLogByShortcut('ctrl+alt+l');
    initSaveLogByPublicAPI('__saveLog__');
})();

// Перенаправляем RuntimeErrors (ошибки во премя подготовки и работы приложения) в логгер
interceptRuntimeErrors(logger);

/** Получить название/сообщение ошибки */
export const getErrorMsg = (err: any): string => `${(err && err.message) || err}`;
