/**
 * Находит наиболее близкую к указанному значению временную отметку,
 * кратную 100 или 1000 в зависимости от величины значения.
 */
export const epsilonTime = (time: number) => {
    const divider = time < 1000 ? 100 : 1000;
    const mod = time % divider;

    let newTime = time - mod;

    if (mod >= (5 * divider) / 10) {
        newTime += divider;
    }

    return newTime;
};
