export enum UflrType {
    /**
     * Запрещёно законодательно.
     * @type {string}
     */
    'blocked' = 'blocked',
    /**
     * Заблокированно по просьбе правообладателя.
     * @type {string}
     */
    'illegal' = 'illegal',
}

/**
 * Unavailable For Legal Reasons
 * @namespace
 */
export class Uflr {
    public static is = (item: { uflr?: UflrType }) => item && (item.uflr === UflrType.illegal || item.uflr === UflrType.blocked);

    public static exclude = (items) => {
        if (Array.isArray(items)) {
            items = items.filter((item) => !Uflr.is(item));
        }

        return items;
    };
}
