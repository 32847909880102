define(function (require, exports, module) {
    'use strict';

    /**
     * @param {string} string
     * @return {string}
     */
    function escapeForHtml(string) {
        if (!string) {
            return string;
        }
        return string.replace(escapeForHtml.pattern, escapeForHtml.replacer);
    }

    /**
     * @type {RegExp}
     */
    escapeForHtml.pattern = /[&<>"]/g;

    /**
     * @type {Object.<string>}
     */
    escapeForHtml.replacements = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
    };

    /**
     * @param {string} character
     * @return {string}
     */
    escapeForHtml.replacer = function (character) {
        return escapeForHtml.replacements[character];
    };

    module.exports = escapeForHtml;
});
