/**
 * @object	Lang
 * @author	RubaXa	<trash@rubaxa.org>
 * @version	1.0
 * Объект для работы с разными языками.
 */

define('ajs/__lang/ajs__lang', [
	'ajs/__json/ajs__json',
	'ajs/__string/ajs__string'
], function (){
	function defined(val, def){
		return    (typeof def == 'undefined')
			? (typeof val != 'undefined')
			: (typeof val == 'undefined' ? def : val)
		;
	}

	window.Lang	= ({

		data:	{},
		vars:	{},
		locals:	[],
		def:	'',
		path:	'',


		init: function ()
		{
			//this.setDataPath(jsLoader.aliases['lang.data']||'');
			this.setLocal(window['LANG_DEFAULT'] || 'RU');	// установка дэвалтной локали
			return	this;
		},


		setDataPath: function (p){ this.path	= p; },


		addLocal: function (l, nl)	// добавить локаль
		{
			if( typeof(l) == 'string' )	l	= l.split(',');

			for( var i = 0; i < l.length; i++ )
			{
				if( !this.data[l[i] = l[i].toUpperCase()] )
				{
					this.locals.push(l[i]);
					this.data[l[i]]	= {};
				}
			}

			if( nl = defined(nl, true) ) this.load(false, nl.constructor === Function ? nl : 0);
			return	this;
		},


		setLocal: function (l)	// default
		{
			this.def	= l.toUpperCase();
			this.addLocal(l);
			return	this;
		},


		load: function (v, s)	// загрузить языковые блоки
		{
			if( v )
			{	// блоки
				if( typeof(v) == 'string' )	v	= v.split(',');
				for( var i = 0, n = v.length; i < n; i++ )
				{
					if( !this.vars[v[i]] )
					{	// отметим, что блок хочет грузидцо
						this.vars[v[i]]	= 1;
					}
				}
			}

			var r	= 0, w = '';
			for( var v in this.vars )
			{
				for( var j = 0, nl = this.locals.length; j < nl; j++ )
				{
					if( !this.data[this.locals[j]][v] )
					{
						this.data[this.locals[j]][v]	= 1;
						if( !r )	r = {};
						if( !r[this.locals[j]] )	r[this.locals[j]]	= [];
						r[this.locals[j]].push(v);
//						wId	= ',lang:'+this.locals[j]+'-'+v;
					}
				}
			}

/*			if( 0 && r )
			{

			}
			else if( s )
			{
				s();
			}
*/
			if(s) {
				s();
			}
		},


		add: function (d)
		{
			var n = [];
			for( var l in d )
			{
				for( var v in d[l] )
				{
					this.data[l][v]	= d[l][v];
					n.push('lang:'+l+'-'+v);
				}
			}
//			jsCore.notify(n);
			return	this;
		},


		get: function (v, l){
			if( v.indexOf('/') > -1 )
			{
				v = v.split('/');
				l = v[0];
				v = v[1]
			}
			return	defined(this.data[l||this.def][v], '');
		},


		str: function (key, def){
			var val	= Lang.data[Lang.def][key];
			return typeof val === 'undefined'
				? (typeof def === 'undefined' ? '' : Lang.str(def))
				: (val + '')
			;
		},


		fileSize: function (s, L)
		{
			var x	= Math.floor(Math.log(s||1) / Math.log(1024));
			return	L ? String.num(Math.round(s/Math.pow(1024, x)), L[x], ' ') : x;
		}


	}).init();

});
