define(['jquery', 'lib/sequence'], function ($, Sequence) {
	'use strict';

	function isTrue(value) {
		return value === true;
	}

	function isNotTrue(value) {
		return value !== true;
	}

	function toArray(iterable) {
		var array = [];
		var i = iterable.length;

		while (i--) {
			array[i] = iterable[i];
		}

		return array;
	}

	var native = function () {// CLOUDWEB-6954
		var shadowRootGetter;
		var olderShadowRootGetter;
		var initialized = false;

		return {
			restore: function () {
				if (initialized) {
					return;
				}

				initialized = true;

				if (!(window.ShadowRoot || window.WebSocket)) {
					return;
				}

				var iFrame = document.createElement('iframe');
				var iFrameSrc;

				try {
					iFrameSrc = URL.createObjectURL(new Blob(['<html></html>'], {type: 'text/html'}));
					iFrame.src = iFrameSrc;

				} catch (reason) {
					return;
				}

				iFrame.style.cssText = 'position:absolute;top:-99px;left:-99px;width:10px;height:10px;z-index:-99;opacity:0;';
				iFrame.addEventListener('load', function () {
					var iFrameWindow = iFrame.contentWindow;

					if (iFrameWindow) {
						var Element = iFrameWindow.Element;

						if (Element) {
							var shadowRootDescriptor;

							try {
								shadowRootDescriptor = Object.getOwnPropertyDescriptor(Element.prototype, 'shadowRoot');

							} catch (reason) {
							}

							if (shadowRootDescriptor) {
								shadowRootGetter = shadowRootDescriptor.get;
							}
						}

						var ShadowRoot = iFrameWindow.ShadowRoot;

						if (ShadowRoot) {
							var olderShadowRootDescriptor;

							try {
								olderShadowRootDescriptor = Object.getOwnPropertyDescriptor(ShadowRoot.prototype, 'olderShadowRoot');

							} catch (reason) {
							}

							if (olderShadowRootDescriptor) {
								olderShadowRootGetter = olderShadowRootDescriptor.get;
							}
						}

						var WebSocket = iFrameWindow.WebSocket;

						if (WebSocket) {
							window.WebSocket = WebSocket;
						}
					}
				});

				document.body.appendChild(iFrame);
			},

			getShadowRoot: function (element) {
				if (shadowRootGetter) {
					return shadowRootGetter.call(element);
				}

				return element.shadowRoot;
			},

			getOlderShadowRoot: function (element) {
				if (olderShadowRootGetter) {
					return olderShadowRootGetter.call(element);
				}

				return element.olderShadowRoot;
			}
		};
	}();

	function AdUnblocker(params) {
		if (params) {
			var selectorPattern = params.selectorPattern;

			if (selectorPattern) {
				this.setSelectorPattern(selectorPattern);
			}
		}

		this.whenIsNotBlockerCssRules = this.whenIsNotBlockerCssRules.bind(this);
		this.disableWhenIsBlockerStyleSheet = this.disableWhenIsBlockerStyleSheet.bind(this);
	}

	AdUnblocker.prototype = {
		constructor: AdUnblocker,

		selectorPattern: new RegExp('::content #ad|\\.content__left > div\\[id\]'),

		setSelectorPattern: function (pattern) {
			if (typeof pattern == 'string') {
				try {
					this.selectorPattern = new RegExp(pattern);

				} catch (error) {}
			}
		},

		getStyleSheets: function () {
			var styleSheets = toArray(document.styleSheets);
			var root = native.getShadowRoot(document.documentElement);

			while (root) {
				styleSheets = styleSheets.concat(toArray(root.styleSheets));
				root = native.getOlderShadowRoot(root);
			}

			return styleSheets;
		},

		getCssRules: function (styleSheet) {
			return styleSheet.cssRules || styleSheet.rules;
		},

		isBlockerSelector: function (selector) {
			return typeof selector == 'string' && this.selectorPattern.test(selector);
		},

		hasBlockerSelector: function (rules) {
			var i = rules.length;

			while (i--) {
				var rule = rules[i];

				if (rule && this.isBlockerSelector(rule.selectorText)) {
					return true;
				}
			}

			return false;
		},

		whenIsNotBlockerCssRules: function (rules) {
			var adUnblocker = this;

			return new Promise(function (resolve, reject) {
				if (adUnblocker.hasBlockerSelector(rules)) {
					reject(new Error('has blocker css rules'));

				} else {
					resolve(true);
				}
			});
		},

		whenIsNotBlockerStyleSheet: function (styleSheet) {
			var adUnblocker = this;

			return new Promise(function (resolve, reject) {
				var rules = adUnblocker.getCssRules(styleSheet);

				if (rules) {
					var packageSize = 100;
					var rulePackages = [];
					var i = rules.length;

					if (i) {
						var rulePackage = [];

						while (i--) {
							if (rulePackage.push(rules[i]) == packageSize) {
								rulePackages.push(rulePackage);
								rulePackage = [];
							}
						}

						if (rulePackage.length) {
							rulePackages.push(rulePackage);
						}

						if (rulePackages.length) {
							new Sequence(rulePackages, adUnblocker.whenIsNotBlockerCssRules).then(function (values) {
								if (values.some(isNotTrue)) {
									reject(new Error('blocker css rule'));

								} else {
									resolve(true);
								}
							});

						} else {
							resolve(true);
						}

					} else {
						resolve(true);
					}

				} else {
					resolve(true);
				}
			});
		},

		disableWhenIsBlockerStyleSheet: function (styleSheet) {
			var adUnblocker = this;

			return new Promise(function (resolve) {
				adUnblocker.whenIsNotBlockerStyleSheet(styleSheet).then(function () {
					resolve(/*disabled:*/ false);

				}, function () {
					styleSheet.disabled = true;
					$(styleSheet.ownerNode).remove();
					resolve(/*disabled:*/ true);
				});
			});
		},

		disableBlockerStyleSheets: function () {
			var adUnblocker = this;

			return new Promise(function (resolve, reject) {
				var styleSheets = adUnblocker.getStyleSheets();

				if (styleSheets.length) {
					new Sequence(styleSheets, adUnblocker.disableWhenIsBlockerStyleSheet).then(function (values) {
						if (values.some(isTrue)) {
							var disabledStyleSheets = values.filter(isTrue).length;

							resolve(disabledStyleSheets);

						} else {
							reject(new Error('not found blocker stylesheets'));
						}
					});

				} else {
					reject(new Error('not found stylesheets'));
				}
			});
		}
	};

	AdUnblocker.restoreNative = function () {
		native.restore();
	};

	return AdUnblocker;
});
