(function () {
    'use strict';

    var URIEntities = {
        decode: function (url) {
            url = url.toString();
            try {
                return decodeURIComponent(url);
            } catch (e) {
                return url;
            }
        },

        encode: function (url) {
            return encodeURIComponent(url.toString()).replace(/%2f/gi, '/');
        },
    };

    if (typeof window != 'undefined') {
        if (typeof define != 'undefined') {
            define(function () {
                return URIEntities;
            });
        } else {
            window.URIEntities = URIEntities;
        }
    }
})();
