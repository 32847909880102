/**
 * @license
 * Extensions for "String" object
 *
 * @author	RubaXa	<trash@rubaxa.org>
 * @version	1.0
 */

define('ajs/__string/ajs__string', function (undef) {
	String._as	= {
					  "'":	new RegExp('(^|[^\\\\])((\\\\)*)\'', 'g')
					, '"':	new RegExp('(^|[^\\\\])((\\\\)*)"', 'g')
				};

	if (!window.defined) {

		var defined = function (val, def){
			return	(typeof def == 'undefined')
				? (typeof val != 'undefined')
				: (typeof val == 'undefined' ? def : val)
				;
		};

	}

	var legacy = {

		_hex: function (h){ return h.length < 2 ? h+''+h : h; },
		rgb2hex: function (c/*String|Array|object*/)
		{
			if( typeof c == 'string' )
			{
				if( c.indexOf(',') < 0 )	return	'#'+ c.replace('#','').replace(/^(\w)(\w)(\w)$/gi, '$1$1$2$2$3$3');
				c = c.replace(/(rgb\(|\)|\s+)/ig,'').split(',');
			}
			else if( c.red ) { c[0] = c.red; c[1] = c.green; c[2] = c.blue; }
			c = String._hex((c[0]*1).toString(16)) + String._hex((c[1]*1).toString(16)) + String._hex((c[2]*1).toString(16));
			return '#'+ c;
		},

		addSlashes: function (s, q)
		{
			return	String(s).replace(String._as[q=(q||"'")], "$1$2\\" + q);
		},

		padZero: function(n, l)
		{
			var s = '' + n;
			while (s.length < l) s = '0' + s;
			return s;
		},

		trim: function (s)
		{
			return	String(s).replace(/^([\s\r\n]+)|([\s\r\n]+)$/g, '');
		},

		num:	function (n, L, i)
		{
		  if( L == null ) return '';

			var x	= ((n%100 <= 10) || (n%100 >= 20)) ? n % 10 : 0;
			var l	= L.length;

			if( l == 2 && x == 1 )	x = 0;
			else if( l == 3 && x > 1 )
			{
				x = x < 5 ? 2 : 0;
			}

		  return   (i ? n + (i && (i!=1) ? i : '') : '') +
							(typeof(L) != 'string'
								? (L[x] ||  L[(x > 1 && x < 5) ? x : 0] || L[x > 1?1:0])
								: L
							)
						;
		},

		ucfirst: function (s)
		{
			return	s.charAt(0).toUpperCase() + s.substr(1);
		},


		repeat: function (s, n)
		{
			var r = '', i = 0;
			for( ; i < n; i++ ) r += s;
			return	r;
		},

		toQuery: function (o)
		{
			var s	= '', i = 0, k, kn, v;

			if( o )
			{
				if( typeof o != 'string' )
				{
					s	= [];
					for( var p in o ) if( p != '' )
					{
						p = encodeURIComponent( p );
						v = defined(o[p], '');
						if( v && v.constructor === Array ){
							for( k = 0, kn = v.length; k < kn; k++ ){
								s[i++] = p +'='+ encodeURIComponent( defined(v[k], '') );
							}
						}
						else {
							s[i++] = p + (v != null && v !== '' ? '='+ encodeURIComponent( v ) : '');
						}
					}
					s	= s.join('&');
				}
				else s = o;
			}

			return	s;
		},


		toObject: function (s){
			var o = {};
			if( typeof s === 'object' ){
				o = s || o;
			}
			else if( s = String(s) )
			{
				if( s.indexOf('?') > -1 || /^http:/.test(s)) s = s.split('?')[1] || '';

				s	= s.split('&');
				for( var i = 0, n = s.length, v, k; i < n; i++ ) if( s[i] != '' )
				{
					s[i]	= s[i].split('=');
					v 		= s[i][1];
					k 		= s[i][0];

					if( typeof v == 'string' )
						try{ v = decodeURIComponent(v); } catch (e) { v = unescape(v); }
					else
						v	= '';

					try{ k = decodeURIComponent(k); } catch (e) { k = unescape(k); }

					o[k]	= v;
				}
				return	o;
			}

			return	o;
		},


		html2text: function (h/*:html*/)
		{
			return	String(h)
							.replace(/&/g, '&amp;')
							.replace(/</g, '&lt;')
							.replace(/>/g, '&gt;')
							.replace(/"/g, '&quot;')
			;
		},


		nl2br: function (t)
		{
			return	String(t).replace(/[\r\n]/g, '<br />');
		},


		wordWrap: function (t/*:Text*/, m/*:MaxWord*/, l/*:MaxLength*/, e/*:End*/, spaceAfterMaxLength)
		{
			t	= String(t).replace(new RegExp('(\\S{'+(m||20)+'})', 'ig'), '$1 ');

			if( l && t.length > l ){
				if (spaceAfterMaxLength) {
					for( l--; l<t.length; l++) if( /\s/.test(t.charAt(l)) ){ break; }
				} else {
					for( l++; l--; ) if( /\s/.test(t.charAt(l)) ){ break; }
				}
				t	= t.substr(0, l) + e;
			}

			return	t;
		},

		supplant: function(str, o)
		{
			return str.replace(/#\{([^\{\}]*)\}/g,
				function(a, b) {
					var r = o[b];
					return typeof r === 'string' || typeof r === 'number' ? r : a;
				}
			);
		},

		numberFormat: function (n/*Number*/, d/*Int*/, f/*String*/, s/*String*/)
		{
			var n = Math.round(n, d).toString().split('.');
			if( s ) for( var i = n[0].length-3; i >= 0; i -= 3 ) n[0] = n[0].substr(0, i) + s + n[0].substr(i);
			return	n[0] + (n[1] ? f + n[1] : '');
		},

		sizeFormat: function(filesize){
			if (filesize >= Math.TB){
				filesize = String.numberFormat(filesize / Math.TB, 2, '.', '') + ' ' + Lang.get('Size').tb;
			} else {
				if (filesize >= Math.GB){
					filesize = String.numberFormat(filesize / Math.GB, 2, '.', '') + ' ' + Lang.get('Size').gb;
				} else {
					if (filesize >= Math.MB){
						filesize = String.numberFormat(filesize / Math.MB, 2, '.', '') + ' ' + Lang.get('Size').mb;
					} else {
						if (filesize >= Math.KB){
							filesize = String.numberFormat(filesize / Math.KB, 0) + ' ' + Lang.get('Size').kb;
						} else {
							filesize = String.numberFormat(filesize, 0) + ' ' + Lang.get('Size').bytes;
						}
					}
				}
			}
			return filesize;
		},

		sprintf: function (txt/*[, args]*/){
			if( typeof txt === 'string' ){
				for( var i = 1, n = arguments.length; i < n; i++ )
					txt = txt.replace('%s', arguments[i]);
			}
			return	txt;
		},

		concat: function ()
		{
			return	Array.prototype.join.call(arguments, '');
		},

		preg_quote: function(str)
		{
			return (str || '').replace(/[-[\]{}()*+?.,\\^$|#]/g, "\\$&")
		}
	};

	for (var method in legacy) {
		String[method] = legacy[method];
	}



	var _String_split_ = String.prototype.split
		, _String_split_shim_isnonparticipating
	;

	/*
	 [BUGFIX, IE lt 9, old safari] http://blog.stevenlevithan.com/archives/cross-browser-split
	 More better solution:: http://xregexp.com/
	 Speed test: http://jsperf.com/js-split
	 */
	if('te'.split(/(s)*/)[1] != void 0 ||
		'1_1'.split(/(_)/).length != 3) {
		_String_split_shim_isnonparticipating = /()??/.exec("")[1] === void 0; // NPCG: nonparticipating capturing group

		String.prototype.split = function (separator, limit) {
			var str = this;
			// if `separator` is not a regex, use the native `split`
			if(!(separator instanceof RegExp)) {//if (Object.prototype.toString.call(separator) !== "[object RegExp]") {
				//http://es5.github.com/#x15.5.4.14
				//If separator is undefined, then the result array contains just one String, which is the this value (converted to a String). If limit is not undefined, then the output array is truncated so that it contains no more than limit elements.
				if(separator === void 0 && limit === 0)return [];

				return _String_split_.call(str, separator, limit);
			}

			var output = []
				, flags = (separator["ignoreCase"] ? "i" : "") +
					(separator["multiline"] ? "m" : "") +
					(separator["extended"] ? "x" : "") + // Proposed for ES6
					(separator["sticky"]   ? "y" : "") // Firefox 3+
				, lastLastIndex = 0
			// Make `global` and avoid `lastIndex` issues by working with a copy
				, separator2
				, match
				, lastIndex
				, lastLength
			;

			separator = new RegExp(separator.source, flags + "g");

			str += ""; // Type-convert
			if (!_String_split_shim_isnonparticipating) {
				// Doesn't need flags gy, but they don't hurt
				separator2 = new RegExp("^" + separator.source + "$(?!\\s)", flags);
			}

			/* Values for `limit`, per the spec:
			 * If undefined: 4294967295 // Math.pow(2, 32) - 1
			 * If 0, Infinity, or NaN: 0
			 * If positive number: limit = Math.floor(limit); if (limit > 4294967295) limit -= 4294967296;
			 * If negative number: 4294967296 - Math.floor(Math.abs(limit))
			 * If other: Type-convert, then use the above rules
			 */
			limit = limit === void 0 ?
				-1 >>> 0 : // Math.pow(2, 32) - 1
				limit >>> 0 // ToUint32(limit)
			;

			if (!limit) {
				return [];
			}

			while (match = separator.exec(str)) {
				// `separator.lastIndex` is not reliable cross-browser
				lastIndex = match.index + match[0].length;

				if (lastIndex > lastLastIndex) {
					output.push(str.slice(lastLastIndex, match.index));

					// Fix browsers whose `exec` methods don't consistently return `undefined` for
					// nonparticipating capturing groups
					// __ NOT WORKING __ !!!!
					if (!_String_split_shim_isnonparticipating && match.length > 1) {
						match[0].replace(separator2, function() {
							for (var i = 1, l = arguments.length - 2; i < l; i++) {
								if (arguments[i] === void 0) {
									match[i] = void 0;
								}
							}
						});
					}

					if (match.length > 1 && match.index < str.length) {
						output.push.apply(output, match.slice(1));
					}

					lastLength = match[0].length;
					lastLastIndex = lastIndex;

					if (output.length >= limit) {
						break;
					}
				}

				if (separator.lastIndex === match.index) {
					separator.lastIndex++; /// Avoid an infinite loop
				}
			}

			if (lastLastIndex === str.length) {
				if (lastLength || !separator.test("")) {
					output.push("");
				}
			} else {
				output.push(str.slice(lastLastIndex));
			}

			return output.length > limit ? output.slice(0, limit) : output;
		}
	}

});
