(function (global) {

	var removeXss = function (value) {
		var lt = /</g,
			gt = />/g,
			ap = /'/g,
			ic = /"/g;

		return value.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");
	};

	function factory() {
		var nargs = /\{([0-9a-zA-Z]+)\}/g,
			slice = Array.prototype.slice;

		// use function from https://github.com/Matt-Esch/string-template
		function process(template) {
			var args;

			if (arguments.length === 2 && typeof arguments[1] === 'object') {
				args = arguments[1]
			} else {
				args = slice.call(arguments, 1)
			}

			if (!args || !args.hasOwnProperty) {
				args = {}
			}

			return template.replace(nargs, function replaceArg(match, i, index) {
				var result;
				var unsafe = false;

				if (template[index - 1] === '{' &&
					template[index + match.length] === '}') {
					return i
				} else {
					result = args.hasOwnProperty(i) ? args[i] : null;

					if (!result) {
						result = args.hasOwnProperty('unsafe-' + i) ? args['unsafe-' + i] : null;
						unsafe = true;
					}

					if (result === null || result === undefined) {
						return ''
					}

					if (unsafe) {
						return result;
					} else {
						return removeXss(result);
					}

				}
			});
		}

		var i18n = function(id, context, data) {

			if (typeof context === 'object') {
				data = context;
				context = null;
			}

			return process(id, data);
		};

		i18n.plural = function(num, lang, glue) {
			var start 	= (glue ? num + (glue && (glue != 1) ? glue : '') : '');
			var form, start;

			if (lang == null) {
				return '';
			}
			if (typeof(lang) == 'string') {
				return start + lang;
			}

			form		= ((num % 100 <= 10) || (num % 100 >= 20)) ? num % 10 : 0;
			var length	= lang.length;

			if (length == 2 && form == 1)	{
				form = 0;
			} else if (length == 3 && form > 1) {
				form = form < 5 ? 2 : 0;
			}

			return start + (lang[form] || lang[(form > 1 && form < 5) ? form : 0] || lang[form > 1 ? 1 : 0]);
		}
		return i18n;
	}

	global.i18n = factory();

	if( typeof define === 'function'){
		define('mrg-i18n-utils', [], function (){ return global.i18n; });
	}

}) (window);
