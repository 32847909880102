define(['jquery'], function($) {

	try {
		CSS.supports('display', 'flex');

	} catch ( error ) {
		return;
	}

	var prefixes = ['-o-', '-ms-', '-moz-', '-webkit-'];

	function cssSupports(property, value) {
		if ( CSS.supports(property, value) ) {
			return true;
		}

		var i = prefixes.length;
		while (i--) {
			var prefixed = prefixes[i] + property;

			if ( CSS.supports(prefixed, value) ) {
				return true;
			}
		}

		return false;
	}

	var root = $(document.documentElement);
	var isOpera = String(window.opera) === "[object Opera]";

	if ( cssSupports('animation-duration', '1s') && !isOpera ) {
		// В Opera 12 Анимация по-факту не работает
		root.addClass('supports-css-animation');
	}

});
