import ajs__date from 'ajs/__date/ajs__date'; // это нужно для феста
import ajs__object from 'ajs/__object/ajs__object'; // эти импорты модифицируют глоб. объекты и это используется
import $ from 'jquery';

window.patron = window.patron || {};

// декоратор отложенных функций
patron.defer = function defer(callback) {
    return function() {
        const d = new $.Deferred();

        callback.apply(null, [d].concat([].slice.call(arguments)));

        return d.promise();
    };
};

if (window.opera && document.body && document.body.classList) {
    document.body.classList.add('opera');
}