/**
 * Библиотека для работы c Office Online.
 *    а теперь еще и с ханкомом.
 * Позволяет загрузить редактор во фрейм и взаимодествовать
 * с ним посредством передачи сообщений через postMessage.
 *
 * Конструктор в качестве параметром может принимать следующие значения:
 *
 *     params = {
 *        url: string, // action формы для отправки во фрейм
 *
 *        data: {
 *            access_token:     string,// токен
 *            access_token_ttl: number // время жизни токена
 *        },
 *
 *        [blurFocus]:      boolean,  // отправка во фрейм сообщения Blur_Focus
 *
 *        [onFrameTimeout]: Function, // слишком долго загружался фрейм
 *        [onFrameError]:   Function, // произошла ошибка загрузки фрейма
 *        [onFrameLoad]:    Function, // успешная загрузка фрейма
 *
 *        [onTimeout]:      Function, // не дождались сообщения App_LoadingStatus
 *        [onMessage]:      Function, // Office Online прислал сообщение
 *        [onStart]:        Function, // Office Online прислал App_LoadingStatus
 *        [onRename]:       Function, // Office Online прислал File_Rename
 *        [onSharing]:      Function, // Office Online прислал UI_Sharing
 *        [onClose]:        Function  // Office Online прислал UI_Close
 *     }
 *
 * Пример использования:
 *
 *     new OfficeOnlineFrame(params).appendTo(document.body)
 *
 * @doc http://wopi.readthedocs.org/en/latest/scenarios/postmessage.html#listening-to-messages-from-the-office-online-iframe
 */
(function() {
    'use strict';

    function createFrame() {
        var frame = document.createElement('iframe');

        frame.id = frame.name = 'office-frame';
        frame.allowFullscreen = true;
        frame.src = 'about:blank';
        frame.style.cssText =
            'visibility: hidden;' + 'position: absolute; top: 0; left: 0;' + 'width: 100%; height: 100%; margin: 0;' + 'border: none';

        return frame;
    }

    function createInput(type, name, value) {
        var input = document.createElement('input');

        input.name = name;
        input.type = type;
        input.value = value;

        return input;
    }

    function createForm(method, action, target, inputs) {
        var form = document.createElement('form');

        form.action = action;
        form.method = method;
        form.target = target;

        Object.keys(inputs).forEach(function(name) {
            form.appendChild(createInput('hidden', name, inputs[name]));
        });

        return form;
    }

    function getOrigin(url) {
        var hyperlink = document.createElement('a');

        hyperlink.href = url;

        return hyperlink.protocol + '//' + hyperlink.hostname;
    }

    function tryParseJSON(jsonString) {
        try {
            return JSON.parse(jsonString);
        } catch (reason) {
            //
        }

        return null;
    }

    function nop() {}

    function OfficeOnlineFrame(params) {
        var url = params.url;
        var frame = createFrame();
        var form = createForm('post', url, frame.name, params.data);

        this._form = form;
        this._frame = frame;
        this._origin = getOrigin(url);

        if ('frameTimeout' in params) {
            this.frameTimeout = params.frameTimeout;
        }

        if ('startTimeout' in params) {
            this.startTimeout = params.startTimeout;
        }

        if ('blurFocus' in params) {
            this.blurFocus = params.blurFocus;
        }

        this._assignCallbacks(params);
        this._listenFrameEvents();
        this._listenFrameMessages();
    }

    OfficeOnlineFrame.prototype = {
        constructor: OfficeOnlineFrame,

        blurFocus: false,

        frameTimeout: 15000,
        startTimeout: 30000,

        _frameTimeoutId: 0,
        _startTimeoutId: 0,

        _frame: null,
        _form: null,

        _reactOnFrameMessages: true,

        _onFrameTimeoutCallback: nop,
        _onFrameErrorCallback: nop,
        _onFrameLoadCallback: nop,
        _onTimeoutCallback: nop,
        _onMessageCallback: nop,
        _onSharingCallback: nop,
        _onRenameCallback: nop,
        _onStartAfterTimeoutCallback: nop,
        _onStartCallback: nop,
        _onCloseCallback: nop,

        _eventTypes: [
            'onStartAfterTimeout',
            'onFrameTimeout',
            'onFrameError',
            'onFrameLoad',
            'onTimeout',
            'onMessage',
            'onSharing',
            'onRename',
            'onStart',
            'onClose',
        ],

        _frameEventTypes: ['load', 'error'],

        _assignCallbacks: function(params) {
            this._eventTypes.forEach(function(eventType) {
                if (eventType in params) {
                    this['_' + eventType + 'Callback'] = params[eventType];
                }
            }, this);
        },

        _listenFrameMessages: function() {
            window.addEventListener('message', this);
        },

        _stopReactOnFrameMessages: function() {
            this._reactOnFrameMessages = false;
        },

        _stopListeningFrameMessages: function() {
            window.removeEventListener('message', this);
        },

        _listenFrameEvents: function() {
            this._frameEventTypes.forEach(function(eventType) {
                this._frame.addEventListener(eventType, this);
            }, this);
        },

        _stopListeningFrameEvents: function() {
            this._frameEventTypes.forEach(function(eventType) {
                this._frame.removeEventListener(eventType, this);
            }, this);
        },

        _postReady: function() {
            this.postMessage(
                JSON.stringify({
                    MessageId: 'Host_PostmessageReady',
                    SendTime: Date.now(),
                    Values: {},
                })
            );
        },

        _postBlurFocus: function() {
            this.postMessage(
                JSON.stringify({
                    MessageId: 'Blur_Focus',
                    SendTime: Date.now(),
                    Values: {},
                })
            );
        },

        _setStartTimeout: function() {
            var officeOnlineFrame = this;

            this._startTimeoutId = window.setTimeout(function() {
                officeOnlineFrame._onStartTimeout();
            }, this.startTimeout);
        },

        _clearStartTimeout: function() {
            var startTimeoutId = this._startTimeoutId;

            if (startTimeoutId) {
                window.clearTimeout(startTimeoutId);
            }
        },

        _setFrameTimeout: function() {
            var officeOnlineFrame = this;

            this._frameTimeoutId = window.setTimeout(function() {
                officeOnlineFrame._onFrameTimeout();
            }, this.frameTimeout);
        },

        _clearFrameTimeout: function() {
            var frameTimeoutId = this._frameTimeoutId;

            if (frameTimeoutId) {
                window.clearTimeout(frameTimeoutId);
            }
        },

        _onStartTimeout: function() {
            this._stopListeningFrameEvents();
            this._stopReactOnFrameMessages();
            this._onTimeoutCallback(new Error('message timeout'));
        },

        _onFrameTimeout: function() {
            this._clearStartTimeout();
            this._stopListeningFrameEvents();
            this._stopReactOnFrameMessages();
            this._onFrameTimeoutCallback(new Error('frame timeout'));
        },

        _onFrameLoadOnce: function() {
            this._clearFrameTimeout();
            this._stopListeningFrameEvents();
            this._makeVisible();
            this._onFrameLoadCallback();
            this._postReady();

            this._onFrameLoadOnce = nop;
        },

        _onFrameErrorOnce: function() {
            this._clearFrameTimeout();
            this._clearStartTimeout();
            this._stopListeningFrameEvents();
            this._stopListeningFrameMessages();
            this._onFrameErrorCallback();
        },

        _onAppLoadingStatusAfterTimeoutMessage: function(message) {
            this._clearFrameTimeout();
            this._clearStartTimeout();
            this._stopListeningFrameEvents();
            this._stopListeningFrameMessages();

            this._onStartAfterTimeoutCallback({
                loadedTime: message.Values.DocumentLoadedTime,
            });
        },

        _onAppLoadingStatusMessage: function(message) {
            this._clearStartTimeout();
            this._onFrameLoadOnce();

            if (this.blurFocus) {
                this._postBlurFocus();
            }

            this._onStartCallback({
                loadedTime: message ? message.Values.DocumentLoadedTime : null,
            });
        },

        _onRenameMessage: function(message) {
            this._onRenameCallback({
                newName: message.Values.NewName,
            });
        },

        _onSharingMessage: function() {
            this._onSharingCallback({});
        },

        _onCloseMessage: function() {
            this._onCloseCallback({});
        },

        _onMessage: function(event) {
            // TODO: fix it for messages from hancon. It uses another origin and message structure and types
            if (this._origin === event.origin) {
                var message = tryParseJSON(event.data);

                if (message) {
                    if (this._reactOnFrameMessages) {
                        switch (message.MessageId) {
                            case 'App_LoadingStatus':
                                this._onAppLoadingStatusMessage(message);
                                break;

                            case 'File_Rename':
                                this._onRenameMessage(message);
                                break;

                            case 'UI_Sharing':
                                this._onSharingMessage(message);
                                break;

                            case 'UI_Close':
                                this._onCloseMessage(message);
                                break;
                        }

                        this._onMessageCallback(message);
                    } else if (message.MessageId === 'App_LoadingStatus') {
                        this._onAppLoadingStatusAfterTimeoutMessage(message);
                    }
                }
            }
        },

        handleEvent: function(event) {
            switch (event.type) {
                case 'message':
                    this._onMessage(event);
                    break;

                case 'load':
                    this._onFrameLoadOnce();
                    break;

                case 'error':
                    this._onFrameErrorOnce();
                    break;
            }
        },

        _makeVisible: function() {
            this._frame.style.visibility = 'visible';
        },

        appendTo: function(container) {
            this._setFrameTimeout();
            this._setStartTimeout();

            var form = this._form;

            container.appendChild(form);
            container.appendChild(this._frame);

            form.submit();

            return this;
        },

        postMessage: function(data) {
            this._frame.contentWindow.postMessage(data, '*');

            return this;
        },

        dispose: function() {
            this._clearFrameTimeout();
            this._clearStartTimeout();
            this._stopListeningFrameEvents();
            this._stopListeningFrameMessages();

            this._frame.src = 'about:blank';

            for (var key in this) {
                if (this.hasOwnProperty(key)) {
                    delete this[key];
                }
            }

            return this;
        },
    };

    if (typeof define != 'undefined') {
        define(function() {
            return OfficeOnlineFrame;
        });
    } else {
        window.OfficeOnlineFrame = OfficeOnlineFrame;
    }
})();
