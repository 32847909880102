/**
 * @author RubaXa <trash@rubaxa.org>
 * @license MIT
 */
(function () {
	"use strict";


	var R_SPACE = /\s+/,
		R_KEYCHAIN_CHECK = /[\[.\]]/,
		R_KEYCHAIN_SPLIT = /(?:\.|['"]?]?\.?\[['"]?|['"]?])/
	;


	/**
	 * Split строки по пробелу
	 * @private
	 * @param   {string} val
	 * @returns {Array}
	 */
	function _split(val) {
		return (val + '').trim().split(R_SPACE);
	}



	/**
	 * Проверить ключи на наличие и истинность
	 * @private
	 * @param   {object}  obj
	 * @param   {string}  keys
	 * @param   {boolean} [exists]
	 * @returns {boolean}
	 */
	function _check(obj, keys, exists) {
		var ret, i, key, not, min, max;

		/* istanbul ignore else */
		if (keys) {
			keys = _split(keys);
			i = keys.length;

			while (i--) {
				key = keys[i];
				not = false;

				if (key.charAt(0) === '!') {// НЕ ключ
					not = true;
					key = key.substr(1);
				}

				if (key.indexOf(':') !== -1) { // переданы аргументы
					key = key.split(':');
					min = key[1];
					max = key[2];
					key = key[0];
				}

				// Получаем значение ключа
				ret = _grep(obj, key);

				if (min !== void 0) { // сравниваем со значением ключа
					ret = (max === void 0)
							? (ret == min)
							: (ret >= min && ret <= max)
						;
				}

				if (exists) { // Проверка наличия ключа
					ret = ret !== void 0;
				} else { // Приводик к boolean
					ret = !!ret;
				}

				if (ret ^ not) { // применяем модификатор (т.е. XOR)
					return true;
				}
			}
		}

		return false;
	}


	/**
	 * Получить/установить/удалить значение по ключу
	 * @private
	 * @param   {object}  obj
	 * @param   {string}  key
	 * @param   {boolean} [set]
	 * @param   {*}       [value]
	 * @returns {*}
	 */
	function _grep(obj, key, set, value) {
		var isUnset = arguments.length === 3;

		if (R_KEYCHAIN_CHECK.test(key)) {
			var chain = key.split(R_KEYCHAIN_SPLIT),
				i = 0,
				n = chain.length,
				curObj = obj,
				prevObj = obj,
				lastKey
			;

			for (; i < n; i++) {
				key = chain[i];
				if (key !== '') {
					lastKey = key;

					if (curObj) {
						prevObj = curObj;
						curObj = curObj[key];
						if (set && !isUnset && !curObj) {
							prevObj[key] = curObj = {};
						}
					}
					else {
						return void 0;
					}
				}
			}

			if (set) {
				if (isUnset) {
					delete prevObj[lastKey];
				} else {
					prevObj[lastKey] = value;
				}
			}

			return curObj;
		}
		else {
			if (set) {
				if (isUnset) {
					delete obj[key];
				} else {
					obj[key] = value;
				}
			}
			return obj[key];
		}
	}



	/**
	 * Работа с конфигами/настройками
	 * @class config
	 * @constructs config
	 * @param   {Object}  [props]  конфиг
	 * @returns {config}
	 */
	var config = function (props) {
		if (!(this instanceof config)) {
			return	new config(props);
		}

		if (props) {
			this.set(props);
		}
	};


	config.fn = config.prototype = /** @lends config# */ {
		constructor: config,

		/**
		 * Проверить свойства на истинность
		 * @param   {string}  keys  название свойств, разделенных пробелом
		 * @returns {boolean}
		 */
		is: function (keys) {
			return _check(this, keys);
		},


		/**
		 * Проверить наличие свойства
		 * @param   {string}  keys  название свойств, разделенных пробелом
		 * @returns {boolean}
		 */
		has: function (keys) {
			return _check(this, keys, true);
		},


		/**
		 * Получить свойство
		 * @param   {string}  key    имя свойства
		 * @param   {*}       [def]  значение по умолчанию
		 * @returns {boolean}
		 */
		get: function (key, def) {
			var retVal = _grep(this, key);
			return retVal === void 0 ? def : retVal;
		},


		/**
		 * Назначить свойство
		 * @param   {Object|string} props   список свойств
		 * @param   {*}  [value]   значение
		 * @return  {config}
		 */
		set: function (props, value) {
			var _attrs = props;

			/* istanbul ignore else */
			if (typeof props !== 'object') {
				_attrs = {};
				_attrs[props] = value;
			}

			/* istanbul ignore else */
			if (_attrs instanceof Object) {
				for (var key in _attrs) {
					/* istanbul ignore else */
					if (_attrs.hasOwnProperty(key)) {
						_grep(this, key, true, _attrs[key]);
					}
				}
			}

			return this;
		},


		/**
		 * Удалить свойство
		 * @param   {string}  key   имя свойства
		 * @returns {config}
		 */
		unset: function (key) {
			var args = arguments, i = args.length;

			while (i--) {
				_grep(this, args[i], true);
			}

			return this;
		}
	};


	/**
	 * Подмешать методы к объекту
	 * @static
	 * @memberof config
	 * @param  {Object}  target  цель
	 * @returns {Object}  возвращает переданный объекь
	 */
	config.apply = function (target) {
		target.is = config.is;
		target.has = config.has;
		target.get = config.get;
		target.set = config.set;
		target.unset = config.unset;
		return	target;
	};


	// Создаем глобальный конфиг
	var globalConfig = config({ __version__: '0.1.0' }), key;
	for (key in globalConfig) {
		config[key] = globalConfig[key];
	}


	// Версия модуля
	config.version = "0.1.0";


	// Export
	if (typeof define === "function" && (define.amd || /* istanbul ignore next */ define.ajs)) {
		define('config', [], function () {
			return config;
		});
	} else if (typeof module != "undefined" && module.exports) {
		module.exports = config;
	} else {
		window.config = window.__config__ = config;
	}
})();
