import AdmanLogger from '@mail/adman-logger';
import { getFeature, isFeature } from 'Cloud/Application/FeaturesEs6';

import { xray } from './xray';

// Нужен, чтобы прокинуть в adman-logger возможность чтения фич (в частности adv-exp-id)
// Чтение фич сделано по аналогии с @mail/features
const featuresConnector = {
    has: isFeature,
    get: (featureName) => {
        const value = getFeature(featureName);
        return {data: value}
    }
}

const logger = new AdmanLogger({
    xray,
    features: featuresConnector
});

export default logger;
