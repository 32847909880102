export const addProtocol = function (url) {
    return url.replace(/^\/\//, `${window.location.protocol}//`); // CLOUDWEB-3901
};

export const addParams = function (url, params) {
    url = url + (url.includes('?') ? '&' : '?');

    const p = [];

    for (const name in params) {
        if (params.hasOwnProperty(name)) {
            p.push([name, params[name]].join('='));
        }
    }

    url += p.join('&');

    return url;
};
