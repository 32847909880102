/**
 * @license
 * @author				Egor Halimonenko <termi1uc1@gmail.com>
 * @compatibility		JavaScript 1.5+
 */

define('ajs/__object/ajs__object', function(){

	"use strict";

	var _hasOwnProperty = Object.prototype.hasOwnProperty;

	Object["extend"] = function extend(target) {
		for( var i = 1, len = arguments.length ; i < len ; i++ ) {
			var source = arguments[i];

			for (var key in source) {
				target[key] = source[key];
			}
		}

		return target;
	};

	Object["append"] = function append(target) {
		for( var i = 1, len = arguments.length ; i < len ; i++ ) {
			var source = arguments[i];

			for (var key in source) if ( !_hasOwnProperty.call(target, key) ) {
				target[key] = source[key];
			}
		}

		return target;
	};

});
