define(function(require, exports, module) {
'use strict';

    function stringToUint8Array(string) {
        var uint8Array = new Uint8Array(string.length);

        for(var i = 0, count = string.length; i < count; i++){
            uint8Array[i] = string.charCodeAt(i);
        }

        return uint8Array;
    }

    module.exports = stringToUint8Array;
});
